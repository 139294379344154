@import './import.css';

.gallery-detail {
  width: 100%;
  display: table;
  margin: 0 0 2em 0;
  &__item {
    min-height: 100px;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    _height: 100px;
    margin: 0 0 1em 0;
    &-link {
      display: inline-block;
      text-decoration: none;
      border: solid 3px transparent;
      clear: both;
      transition: all 0.2s linear;
      &:hover {
        border: solid 3px #D5542F;
        transition: all 0.2s linear;
      }
      &-image {
        vertical-align: bottom;
      }
    }
    &-desc {
      max-width: 220px;
      font: 0.8em var(--font);
      color: #444;
      margin: 0;
      padding: 0.5em 0 0.5em 0;
      line-height: 1.4em;
      display: block;
    }
  }
  &__title {
    margin: 0.5em 0 0 0;
    &-link {
      color: #008ACE;
      font: 0.85em var(--font);
      &:hover {
        color: #D5542F;
      }
    }
  }
  &__desc {
    width: 100%;
    padding: 1em 0 0 0;
    clear: both;
  }
}
