@import './import.css';

.gallery-popup .popup-gallery {
  width: 860px;
  min-width: 860px;
  min-height: 600px;
  background: #fff;
  box-shadow: 0 0 30px 0 #555;
  padding: 2em 2em 1em 2em;
  &-info {
    padding: 1em 0 0 0;
  }
  &-desc {
    width: 80%;
    padding: 1em 0 0.5em 0;
    font: .85em var(--font);
    display: none;
  }
  &-link {
    width: 30%;
    font: 0.85em var(--font);
    padding: 0.5em 0 0 0;
    color: #777;
    float: left;
    &-text {
      color: #777;
      position: relative;
      display: inline-block;
      padding: 0 1.6em 0 0;
      &::after {
        font: 1.2em FontAwesome;
        content: '\f08e';
        display: inline-block;
        position: absolute;
        top: 0.05em;
        right: 0;
        color: #777;
      }
    }
    &-info {
      display: block;
    }
    &:hover .popup-gallery-link-text,
    &:hover .popup-gallery-link-text::after {
      color: #000;
    }
  }
  &-count {
    font: 0.85em var(--font);
    float: right;
    padding: 0.5em 0 0 0;
    color: #000;
  }
}
