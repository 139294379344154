@import './import.css';

.gallery_type_page {
  & .gallery-list {
    display: table;
    &__item {
      width: auto;
      margin: 0;
    }
  }
}
