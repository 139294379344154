@import './import.css';

.gallery_type_tile {
  width: 100%;
  height: 100%;
  margin: 30px 0 0 0;
  & .gallery-detail {
    width: 100%;
    height: 600px;
    position: relative;
    display: block;
    &__item {
      width: 180px;
      height: 180px;
      min-height: 0;
      margin: 0;
      display: inline-block;
      position: relative;
      overflow: hidden;
      &:nth-child(2n) {
        width: 360px;
        height: 360px;
      }
      &:nth-child(4n+1) {
        width: 180px;
        height: 180px;
      }
      &:nth-child(6n) {
        width: 240px;
        height: 240px;
      }
      &-link {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: transform 1s ease-in-out;
        border: none;
        &:hover {
          background-position: 50% 50%;
          transition: transform 1s ease-in-out;
          transform: scale(1.5);
          border: none;
        }
        &-image {
          visibility: hidden;
          vertical-align: bottom;
        }
      }
    }
  }
}
