@import './../import.css';

.gallery-list_type_inline {
  position: relative;
  display: table;
  margin: 0 0 0 -3px;
  & .gallery-list__item {
    width: auto;
    display: inline-block;
    margin: 0;
    padding: 0;
    &-link {
      margin: 0;
      padding: 0;
      border: solid 3px #fff;
      &:hover {
        border: solid 3px #d5542f;
      }
      &-image {
        vertical-align: bottom;
      }
    }
  }
}
