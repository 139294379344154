@import './import.css';

.gallery-title {
  padding: 0 0 0.5em 0;
  color: #535353;
  font: 1.4em var(--font);
  &__link {
    color: #008ACE;
    &:hover {
      color: #CC0000;
    }
  }
}
