@import './../import.css';

.gallery-list {
  width: 100%;
  display: table;
  &__item {
    width: 25%;
    min-height: 100px;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    _height: 100px;
    margin: 0 0 2em 0;

    &-link {
      display: inline-block;
      text-decoration: none;
      transition: all 0.2s linear;
      &-text {
        width: 90%;
        color: #008ace;
        font: 1em var(--font);
        display: block;
        padding: 1em 0 0.3em 0;
        text-decoration: underline;
      }
      &:hover {
        .gallery-list__item-image {
          box-shadow: #999 0 0 0.5em;
        }
        &::after,
        &::before {
          box-shadow: #999 0 0 0.5em;
        }
        .gallery-list__item-link-text {
          color: #cc0000;
          transition: all 0.2s linear;
        }
      }
    }
    &-image {
      display: inline-block;
      position: relative;
      box-shadow: #bbb 0 1px 3px;
      transition: all 0.2s linear;
      &::before {
        background-color: #ffffff;
        box-shadow: #ccc 0 1px 3px;
        content: "";
        height: 100%;
        margin-top: 4px;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        top: 0;
        left: 0;
        transition: all 0.2s linear;
      }
      &::after {
        background-color: #ffffff;
        box-shadow: #ccc 0 1px 0;
        content: '';
        height: 100%;
        margin-top: 2px;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        display: block;
        top: 0;
        left: 0;
        transition: all 0.2s linear;
      }
      &-image {
        vertical-align: bottom;
        border: 3px solid #fff;
        box-shadow: #ccc 0 1px 0;
        position: relative;
        z-index: 1;
      }
    }
    &-desc {
      padding: 0 2em 0 0;
      display: block;
    }
    &-count {
      display: block;
      font: 0.75em var(--font);
      color: #777;
      padding: 0.3em 0 0 0;
    }
  }
}
