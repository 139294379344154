@import './import.css';

.gallery-not-exist {
  width: 100%;
  border: solid 1px #ccc;
  padding: 2em;
  text-align: center;
  position: relative;
  margin: 2em 0;
  & p {
    font: 0.95em var(--font);
    color: #000;
    padding: 0;
    margin: 0;
    position: relative;
  }
}
