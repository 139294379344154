@import './import.css';

.gallery_type_fotorama {
  position: relative;
  & .gallery-title {
    padding: 0 0 0.5em 0;
    color: #535353;
    font: 1.4em var(--font);
  }
}
